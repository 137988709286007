<template>
  <div class="form-container">
    <div class="text-center text-heading --w-bold --prm py-5">{{ $t('booking.thanksForBooking') }}</div>

    <div class="px-5">
      {{ $t('booking.bookingConfirmed') }}
      <!-- Reservation Details START-->
      <UsageHistoryItem
        :id="facilityInfo.id"
        :title="facilityInfo.name"
        :urlImg="facilityInfo.mainImageUrl"
        :roomTypeName="roomType.name"
        :checkInDate="checkInDate"
        :checkOutDate="checkOutDate"
        class="px-0"
        :isActive="true"
        :bgWhite="true"
      />
      {{ $t('booking.confirmationSentToEmail', { email: myEmail }) }}
      <v-btn
        elevation="0"
        :to="{name: 'home'}"
        class="my-4 text-center"
        rounded
        block
        color="btn-grad--orange"
        x-large
      >
        <span class="text-heading font-weight-medium w-100 --white">{{ $t('buttons.backToMainPage') }}</span>
        <IconChevronRightCircle color="white" width="23" height="23" class="mr-2"/>
      </v-btn>
<!--      FACILITY info button -->
<!--      <div class="text-center">-->
<!--        <v-btn-->
<!--          elevation="0"-->
<!--          rounded-->
<!--          outlined-->
<!--          x-large-->
<!--          class="text-heading mt-4 mb-12 font-weight-medium bg-white &#45;&#45;border-prm text-decoration-underline"-->
<!--          @click="goToFacility"-->
<!--        >-->
<!--          {{ $t('buttons.backToMainPage') }}-->
<!--        </v-btn>-->
<!--      </div>-->
    </div>
</div>
</template>

<script>
import UsageHistoryItem from '@/components/UsageHistoryItem'
import searchVacancyMixin from '../searchVacancyMixin'
import IconChevronRightCircle from '@/components/icons/IconChevronRightCircle'

export default {
  name: 'Complete',
  mixins: [searchVacancyMixin],
  components: {
    UsageHistoryItem,
    IconChevronRightCircle
  },
  computed: {
    myEmail () {
      return this.$store.state.user.userInfo.email
    }
  }
  // methods: {
  //   async goToFacility () {
  //     await this.$router.push({
  //       name: 'home-booking-reservation-details-detail',
  //       params: { id: this.facilityInfo.id.toString() }
  //     })
  //   }
  // }
}
</script>
